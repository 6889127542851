import { Component, OnInit } from '@angular/core';

import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgClass, NgIf } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { ResetWalletPasswordUseCase } from 'src/app/core/usecases/wallet/reset-wallet-password.usecase';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { LoaderComponent } from '../../shared/components/loader/loader.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-wallet-password.component.html',
  styleUrls: ['./reset-wallet-password.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, LoaderComponent, FormsModule, ReactiveFormsModule],
})
export class ResetWalletPasswordComponent implements OnInit {
  private id: string;

  private resetToken: string;

  public submitted = false;

  public errorMessage = '';

  public successMessage = '';

  public loading = false;

  public isMobile: boolean;

  public resetForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private responsiveService: ResponsiveService,
    private resetWalletPasswordUseCase: ResetWalletPasswordUseCase,
  ) {
    this.resetForm = this.formBuilder.group(
      {
        password: new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.minLength(9)]),
        ),
        confirmPassword: new UntypedFormControl('', Validators.compose([Validators.required])),
      },
      {
        validators: this.passwordMatchCheck.bind(this),
      },
    );
  }

  passwordMatchCheck(formGroup: UntypedFormGroup) {
    const { value: password } = formGroup.get('password')!;
    const { value: confirmPassword } = formGroup.get('confirmPassword')!;
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  ngOnInit(): void {
    this.getIsMobile();
    this.route.params.subscribe({
      next: (params) => {
        this.id = params.id;
        this.resetToken = params.resetToken;
      },
    });
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }

  get password(): AbstractControl {
    return this.resetForm.get('password')!;
  }

  get confirmPassword(): AbstractControl {
    return this.resetForm.get('confirmPassword')!;
  }

  public navigateToSignIn() {
    this.router.navigate(['wallet']);
  }

  public onSubmit(): void {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.resetForm.invalid) {
      this.loading = true;
      this.resetWalletPasswordUseCase
        .execute({ data: this.resetForm.value, id: this.id, resetToken: this.resetToken })
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.successMessage = 'تم تعديل  كلمة سر المحفظة';
          },
          error: () => {
            this.errorMessage = 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرى';
          },
        });
    }
  }
}
