import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { ResetWalletPasswordComponent } from './reset-wallet-password.component';

@NgModule({
  imports: [ResetWalletPasswordComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: ResetWalletPasswordComponent,
      },
    ]),
  ],
})
export class ResetWalletPasswordModule {}
