<div class="background">
  <div
    [ngClass]="{
      'container-custom-mob': isMobile,
      'container-custom': !isMobile
    }"
  >
    <div>
      <div *ngIf="!successMessage" class="title">طلب تغيير كلمة مرور المحفظة</div>
      <div class="card main">
        <div *ngIf="errorMessage && !loading" class="alert alert-danger form-error-message">
          {{ errorMessage }}
        </div>

        <div *ngIf="successMessage && !loading">
          <img loading="lazy" src="assets/img/check.svg" class="check" />
          <div class="success-title">تم تغيير كلمة مرور المحفظة بنجاح</div>
          <button
            class="btn btn-signIn"
            [ngClass]="{ 'btn-register-mob': isMobile }"
            (click)="navigateToSignIn()"
            type="submit"
          >
            تسجيل الدخول إلي المحفظة
          </button>
        </div>

        <loader [loading]="loading"></loader>
        <div class="card-content" *ngIf="!loading && !successMessage">
          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label> كلمة السر * </label>
              <input
                class="form-control"
                type="password"
                placeholder="أدخل كلمة السر"
                formControlName="password"
              />
              <div *ngIf="password.invalid && submitted">
                <div *ngIf="password.errors?.required" class="input-error">كلمة السر مطلوبة</div>
                <div *ngIf="password.errors?.minlength" class="input-error">
                  كلمة السر يجب ألا تقل عن ٩ حروف
                </div>
              </div>
            </div>

            <div class="form-group">
              <label> تأكيد كلمة السر * </label>
              <input
                class="form-control"
                type="password"
                placeholder="أدخل تأكيد كلمة السر"
                formControlName="confirmPassword"
              />
              <div *ngIf="confirmPassword.invalid && submitted">
                <div *ngIf="confirmPassword.errors?.required" class="input-error">
                  تأكيد كلمة السر مطلوبة
                </div>
              </div>
              <div *ngIf="resetForm.errors" class="input-error">
                تأكيد كلمة السر لا تتطابق مع كلمة السر
              </div>
            </div>

            <button
              class="btn btn-register"
              [ngClass]="{ 'btn-register-mob': isMobile }"
              type="submit"
            >
              تغيير كلمة السر
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
